<template>
  <footer class="text-light">
    <div class="bg-secondary">
      <div class="container">
        <div class="row mb-5 pt-5 justify-content-between">
          <div class="col-12 col-lg-6 text-center text-lg-left mt-5">
            <img alt="DWG logo"  class="d-inline-block align-top img-fluid logo" src="@/assets/img/dwg-logo-transparent-with-strap.webp">
          </div>
          <div class="col-12 col-lg-3 mt-5 text-center text-lg-left">
            <h4 class="mb-4">Explore more</h4>
            <ul>
              <li>
                 <b-link class="text-white" :to="{ name: 'wp-page', params: { slug: 'about' } }">About DWG</b-link>
              </li>

              <li>
                <b-link :to="{ name: 'team', params: {} }" class="text-white">DWG Team</b-link>
              </li>

              <li>
                <b-link class="text-white" :to="{ name: 'wp-page', params: { slug: 'new-to-dwg'} }">New to DWG?</b-link>
              </li>
            </ul>
          </div>
          <div class="col-12 col-lg-3 text-md-left mt-5">
            <h4 class="mb-4 text-center text-lg-right">Connect with us</h4>
            <div class="d-flex justify-content-center justify-content-lg-end flex-wrap social-btns social-btns-light mx-auto">
              <div class="mr-3">
                <a href="https://www.linkedin.com/company/digital-workplace-group" target="_blank">
                  <svg
                    class="svg-inline--fa fa-linkedin fa-w-14 fa-2x" aria-hidden="true" focusable="false"
                    data-prefix="fab" data-icon="linkedin" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512" data-fa-i2svg="">
                    <path fill="currentColor"
                      d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z">
                    </path>
                  </svg><!-- <i class="fab fa-2x fa-linkedin"></i> Font Awesome fontawesome.com -->
                </a>
              </div>
              <div class="">
                <a href="https://www.youtube.com/channel/UCBwjz2hXcQLdR-zb3Oyzs3Q" target="_blank">
                  <svg
                    class="svg-inline--fa fa-youtube fa-w-18 fa-2x" aria-hidden="true" focusable="false"
                    data-prefix="fab" data-icon="youtube" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512" data-fa-i2svg="">
                    <path fill="currentColor"
                      d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z">
                    </path>
                  </svg><!-- <i class="fab fa-2x fa-youtube"></i> Font Awesome fontawesome.com -->
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row copyright">
          <div class="col-12">
            <p class="small text-muted mb-3">DWG and Digital Workplace Group trading names of Digital Workplace Forum Group Limited UK (Company No. 02776450) and Digital Workplace Group USA Inc.</p>
          </div>

          <div class="col-12 legal">
            <div>
              <div class="text-white-50">
                <span class="d-block d-lg-inline mb-3">© {{ new Date().getFullYear() }} Digital Workplace Forum Group.</span>
                <ul id="menu-legal-links" class="legal text-light">
                  <li>
                    <a href="/terms-conditions">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="/privacy-statement">Privacy Policy</a>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        v-model="showTermsModal"

        size="xl"
        scrollable
        cancel-disabled
        data-bs-backdrop="static"
        @ok="acceptTerms"
        @close="noDismiss"
        @hide="noDismiss"
      >
        <template #modal-header="">
          <div>
            <h2>User Agreement</h2>
            <p>Please accept the user agreement before continuing to use the extranet.</p>
          </div>
        </template>
        <template>
          <div class="row">
            <div class="col">
              <div>
                <p>A key aspect of DWG membership is confidentiality. On joining DWG, Member organisations have agreed to mutually share their digital workplace experiences openly to support peer to peer learning and keep that learning within their own organisations and the membership community. All content on this extranet must be treated the same way.<br /><br />By checking this box, you are accepting the <a target="_blank" href="/terms-conditions">Membership Terms of Use</a> and the <a target="_blank" href="/privacy-statement">Privacy Statement</a></p>
                <hr />
                <h4>Registrations and authorisations</h4>
                <p>Our VAT number is 606-0744-62</p>
                <hr />
                <h4>Our details</h4>
                <p>The full name of our company is Digital Workplace Forum Group Limited T/A Digital Workplace Group.</p>
                <p>We are registered under registration number 2776450 England &amp; Wales</p>
                <p>Our address is 2 Leman Street, London, E1W 9US</p>
                <p>You can contact us by email to&nbsp;<a class="email" href="mailto:admin@digitalworkplacegroup.com" target="_blank" rel="noopener">admin@digitalworkplacegroup.com</a></p>
              </div>
            </div>
          </div>

        </template>
        <template #modal-footer="{ ok }">
          <div class="row mt-4">
            <b-form-checkbox
                id="agreeTerms"
                v-model="termsOptIn"
                name="agreeTerms"
                value="1"
                unchecked_value="0"
                class="mr-5 mt-2"
                @change="allowContinue"
            >
              I accept these terms
            </b-form-checkbox>

            <b-button @click="ok()" :disabled="continueDisabled">Continue</b-button>

          </div>
        </template>
      </b-modal>
    </div>
  </footer>

</template>

<script>
import store from "@/store";
import router from "@/router";
import jwt from '@/auth/jwt/useJwt'

export default {
  name: 'PageFooter',
  data() {
    return {
      termsOptIn: 0,
      continueDisabled: true,
      acceptedAgreement: false,
      showTermsModal: false,
    }
  },
  mounted() {
    this.checkUserTerm()
  },
  methods: {
    noDismiss(e) {
      if(!this.acceptedAgreement) e.preventDefault()
    },
    allowContinue() {
      this.continueDisabled = !this.termsOptIn
    },
    checkUserTerm() {
      jwt.getUserData() && store.dispatch('app/checkUserAgreement')
        .then()
        .catch(error => {
          if (error.response.status === 404) {
            this.showTermsModal = true;
            if (router.currentRoute.name === 'Terms & Conditions') this.showTermsModal = false;
            if (router.currentRoute.name === 'Privacy Statement') this.showTermsModal = false;
          }
        })
    },
    acceptTerms() {
      store.dispatch('app/acceptAgreement').then().catch()
      this.acceptedAgreement = true
      this.showTermsModal = false
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>


