<template>
  <div id="app" :key="$route.path">
    <PageHeader></PageHeader>

    <div v-if="userData && userData.status=='trial'" class="w-100 bg-pink d-flex align-items-center flex-row justify-content-center" style="height:100px">
      <div v-if="userData.organisation.trial_expires" class="text-white h3 m-0">Your trial ends in {{daysTillExpiry}} days</div>
      <div v-else class="text-white h3 m-0">Trail version</div>
    </div>

    <!-- Page loading spinner-->
    <div id="page-load-indicator" class="page-loading active">
        <div class="page-loading-inner">
            <div class="page-spinner"></div><span>Loading...</span>
            <div>
              <a href="/" class="text-muted" style="font-size: small;"><p class="mt-4">If this is taking longer than usual click here to return to the homepage.</p></a>
            </div>
        </div>
    </div>

    <!-- Search modal -->
    <b-modal id="modal-search" title="Search" hide-footer @shown="autoFocusInput">
      <ais-instant-search
        :search-client="searchClient"
        :index-name="env.VUE_APP_ALGOLIA_PREFIX+'_content'"
      >
        <ais-index :index-name="env.VUE_APP_ALGOLIA_PREFIX+'_orgs'" />
        <ais-index :index-name="env.VUE_APP_ALGOLIA_PREFIX+'_users'" />
        <ais-configure :hitsPerPage="6" />
        <ais-autocomplete>
          <template v-slot="{ currentRefinement, indices, refine }">
            <input
              type="search"
              ref="autoFocusMe"
              :value="currentRefinement"
              placeholder="Start typing to search..."
              @input="refine($event.currentTarget.value)"
              class="w-100 px-2"
              v-on:keydown.enter="$router.push({ name: 'search', query: { query: currentRefinement } })"
            />
            <div v-if="currentRefinement">
              <div v-for="index in indices" :key="index.indexId">
                <ul class="list-unstyled">
                  <li v-for="hit in index.hits" :key="hit.objectID">
                    <div v-if="hit.content_type_label != FILES_CONTENT_TYPE">
                      <auto-complete-result v-if="index.indexName === env.VUE_APP_ALGOLIA_PREFIX+'_content'" :content-item="hit" show-content-type></auto-complete-result>
                      <auto-complete-result-org v-if="userData && userData.status !== 'trial' && index.indexName === env.VUE_APP_ALGOLIA_PREFIX+'_orgs'" :content-item="hit" show-content-type></auto-complete-result-org>
                      <auto-complete-result-user v-if="userData && userData.status !== 'trial' && index.indexName === env.VUE_APP_ALGOLIA_PREFIX+'_users'" :content-item="hit" show-content-type></auto-complete-result-user>
                    </div> 
                  </li>
                </ul>
              </div>
            </div>
            <p class="text-right mt-3 mb-0">
              <b-button class="ml-3" @click="$bvModal.hide('modal-search')">Close</b-button>
              <b-button class="ml-3" variant="primary" :to="{ name: 'search', query: { query: currentRefinement } }">Advanced search</b-button>
            </p>
          </template>
        </ais-autocomplete>
      </ais-instant-search>
    </b-modal>

    <router-view/>

    <ShapeDivider css="text-secondary"></ShapeDivider>

    <PageFooter></PageFooter>
  </div>
</template>

<script>

  import { AisInstantSearch, AisAutocomplete } from 'vue-instantsearch'
  import algoliasearch from 'algoliasearch/lite'

  // This will be populated in `beforeCreate` hook
  import ShapeDivider from '@/components/ShapeDivider.vue'
  import PageFooter from '@/components/PageFooter.vue'
  import PageHeader from '@/components/PageHeader.vue'

  import AutoCompleteResult from "@/components/AutoCompleteResult"
  import AutoCompleteResultOrg from "@/components/AutoCompleteResultOrg"
  import AutoCompleteResultUser from "@/components/AutoCompleteResultUser"

  import { ref } from "vue"
  import jwt from "@/auth/jwt/useJwt";

  // import { provideToast } from 'vue-toastification/composition'

  export default {
    components: {
      ShapeDivider,
      PageFooter,
      PageHeader,
      AisInstantSearch,
      AisAutocomplete,
      AutoCompleteResult,
      AutoCompleteResultOrg,
      AutoCompleteResultUser,
    },
    data: function() {
      return {
        searchClient: algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID,process.env.VUE_APP_ALGOLIA_PUB_KEY)
      }
    },
    watch: {
      // Dynamic title and header on "page" load
      '$route' (to) {
        document.title = to.meta.pageTitle || 'DWG Extranet'
      }
    },
    methods: {
      autoFocusInput: function() {
        this.$refs.autoFocusMe.focus()
      }
    },
    setup() {
      // Provide toast for Composition API usage
      // This for those apps/components which uses composition API
      // Demos will still use Options API for ease


      // TODO FIX TOAST
      // provideToast({
      //   hideProgressBar: true,
      //   closeOnClick: false,
      //   closeButton: false,
      //   icon: false,
      //   timeout: 3000,
      //   transition: 'Vue-Toastification__fade',
      // })


      const userData = ref({})
      userData.value = jwt.getUserData()

      const daysTillExpiry = ref(60);

      if(userData.value) {
        const expiryDate = new Date(userData.value.organisation.trial_expires);
        const currentDate = new Date();
        const diffTime = Math.abs(expiryDate - currentDate);
        daysTillExpiry.value = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      }

      const FILES_CONTENT_TYPE = "Files"


      const env = process.env
      return {
        daysTillExpiry,
        userData,
        FILES_CONTENT_TYPE,
        env
      }
    },
  }

</script>

<style lang="scss">
  // Smooth scroll
  html {
    scroll-behavior: smooth;
  }

  .page-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all .4s .2s ease-in-out;
    transition: all .4s .2s ease-in-out;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
  }
  .page-loading.active {
    opacity: 1;
    visibility: visible;
  }
  .page-loading-inner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    opacity: 0;
  }
  .page-loading.active > .page-loading-inner {
    opacity: 1;
  }
  .page-loading-inner > span {
    display: block;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    color: #737491;
  }
  .page-spinner {
    display: inline-block;
    width: 2.75rem;
    height: 2.75rem;
    margin-bottom: .75rem;
    vertical-align: text-bottom;
    border: .15em solid #12465e;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner .75s linear infinite;
    animation: spinner .75s linear infinite;
  }
  @-webkit-keyframes spinner {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes  spinner {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>
